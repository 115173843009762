
import { defineComponent } from 'vue';
import { Form, Field } from 'vee-validate';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import Datatable from '@/components/kt-datatable/kTDatatableLeft.vue';
import TraineeEditModal from '@/layout/header/partials/trainee/TraineeEditDrawer.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import moment from 'moment';
import axios from 'axios';
import { ElNotification } from 'element-plus';
export default defineComponent({
    mixins: [apiEndpoint],
    name: 'trainee show',
    components: {
        Form,
        Field,
        Datatable,
        TraineeEditModal,
        Swal,
        ElNotification,
    },
    data() {
        return {
            moment: '' as any,
            api_url: '',
            traineeList: [],
            industry: [],
            trainee: {
                entity_id: '' as any,
                training_institute_id: '',
                bill_no: '',
                random_per: '',
                tranche_id: '' as any,
            },
            tableHeader: [

                {
                    name: 'Sl',
                    key: 'sl',
                    sortable: true,
                    width: '5px',
                },
                {
                    name: 'Entity',
                    key: 'entity',
                    sortable: true,
                },
                {
                    name: 'Training Institute',
                    key: 'institute',
                    sortable: true,
                },
                {
                    name: 'Course Info',
                    key: 'course_info',
                    sortable: true,
                },
                {
                    name: 'Tranche',
                    key: 'tranche',
                    sortable: true,
                },
                {
                    name: 'Batch Info',
                    key: 'batch_info',
                    sortable: true,
                },
                {
                    name: 'Reg No',
                    key: 'reg_no',
                    sortable: true,
                    width: '170px',
                },

                {
                    name: 'Name',
                    key: 'name',
                    sortable: true,
                    width: '200px',
                },
                {
                    name: 'Gender',
                    key: 'gender',
                    sortable: true,
                },
                {
                    name: 'Date of Birth',
                    key: 'birth_date',
                    sortable: true,
                    width: '150px',
                },
                {
                    name: 'Mobile',
                    key: 'phone',
                    sortable: true,
                },
                {
                    name: 'Alt Mobile',
                    key: 'alt_mob',
                    sortable: true,
                    width: '150px',
                },
                {
                    name: 'Organization Name',
                    key: 'org_name',
                    sortable: true,
                    width: '150px',
                },
                {
                    name: 'Organization Address',
                    key: 'org_add',
                    sortable: true,
                    width: '150px',
                },
                {
                    name: 'Joining Date',
                    key: 'j_date',
                    sortable: true,
                    width: '150px',
                },
                {
                    name: 'Designation',
                    key: 'designation',
                    sortable: true,
                    width: '150px',
                },
                {
                    name: 'Salary',
                    key: 'salary',
                    sortable: true,
                },
                {
                    name: 'Batch Start Date',
                    key: 'start_date',
                    sortable: true,
                    width: '120px',
                },
                {
                    name: 'Batch End Date',
                    key: 'end_date',
                    sortable: true,
                    width: '120px',
                },
            ],
            institutes: [],
            associations: [] as any,
            tranches: [],
            allBillNo: [],
            total_trainee: '',
            entity_type: '',
            user_role: '',
            loading: false,
            load: false,
            courseInfoData: false,
            showtraineeInfo: false,
            actionEdit: false,
            actionDelete: false,
            componentKey: 0,
            pdfHeader: [] as any,
            userInfo: {
                employee: {
                    entity_organogram: [],
                } as any,
            },
        };
    },
    async created() {
        this.moment = moment;
        await this.associationList();
        await this.getTranches();
        this.api_url = this.VUE_APP_API_URL;
        this.emitter.on('trainee-updated', async () => {
            this.getTraineeList();
        });
        this.entity_type = VueCookieNext.getCookie('_seip_entity_type');
        this.userInfo = VueCookieNext.getCookie('_seip_user');
        this.user_role =
            this.userInfo?.employee?.entity_organogram[0]?.entitytyperole?.role_title;
    },
    methods: {
        async downloadPdf() {
           ElNotification({
                dangerouslyUseHTMLString: true,
                message:
                '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
                duration: 0,
            });
            let formData = new FormData();


            formData.set('entity_id', this.trainee.entity_id);
            formData.set('tranche_id', this.trainee.tranche_id);
            formData.set('training_institute_id', this.trainee.training_institute_id);
            formData.set('bill_no', this.trainee.bill_no);
            formData.set('random_per', this.trainee.random_per);

            await ApiService.post('trainee/jobplacement_trainee_list_pdf', formData)
                .then((response) => {
                    ElNotification.closeAll();
                    Swal.fire({
                        title: 'Download Successfull!',
                        icon: 'success',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'View/Download PDF',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // redirect to new tab

                            let path = `${this.VUE_APP_API_URL}/${response.data}`;

                            window.open(path, '_blank');
                        }
                    });
                })
                .catch(({ response }) => {
                    console.log(response);
                    ElNotification.closeAll();
                });
        },


        async printPdf() {
            let formData = new FormData();

            formData.set('entity_id', this.trainee.entity_id);
            formData.set('tranche_id', this.trainee.tranche_id);
            formData.set('training_institute_id', this.trainee.training_institute_id);
            formData.set('bill_no', this.trainee.bill_no);
            formData.set('random_per', this.trainee.random_per);

            let data = `${this.VUE_APP_API_URL}/api/trainee/jobplacement_trainee_list_print`;
            axios
                .post(data, formData)
                .then(response => {
                    //window.open(data, '_blank');
                    document.write(response.data);
                    window.print();
                    location.reload();
                    //open the new window and write your HTML to it         
                })
        },

        async exportTrainee() {
             ElNotification({
                dangerouslyUseHTMLString: true,
                message:
                '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
                duration: 0,
            });
            let formData = new FormData();
            formData.set('entity_id', this.trainee.entity_id);
            formData.set('tranche_id', this.trainee.tranche_id);
            formData.set('training_institute_id', this.trainee.training_institute_id);
            formData.set('bill_no', this.trainee.bill_no);
            formData.set('random_per', this.trainee.random_per);
            axios
                .post(
                    `${this.VUE_APP_API_URL}/api/trainee/jobplacement_trainee_list_Export`,
                    formData,
                    {
                        responseType: 'blob',
                    }
                )
                .then((response) => {
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: 'application/vnd.ms-excel',
                        })
                    );
                    ElNotification.closeAll();
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Job PlacementTrainee List.xlsx');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((response) => {
                    console.log(response);
                    ElNotification.closeAll();
                });
        },
        async trancheChange() {
            this.trainee.entity_id = '';
            this.trainee.training_institute_id = '';
            this.trainee.bill_no = '';
            this.trainee.random_per = '';
            this.showtraineeInfo = false;
        },
        async getTranches() {
            await ApiService.get('configurations/tranche/list')
                .then((response) => {
                    this.tranches = response.data.data;
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },
        async associationList() {
            let entity_id = this.trainee.entity_id;
            if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
                entity_id = VueCookieNext.getCookie('_entity_id');
            }
            this.load = true;
            await ApiService.get(
                this.VUE_APP_GET_ENTITY_LIST_API + '?entity_id=' + entity_id
            )
                .then((response) => {
                    this.associations = response.data.data;
                    this.load = false;
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },

        async trainingInstitute() {
            this.getClaim3Bill();
            this.trainee.training_institute_id = '';
            this.trainee.bill_no = '';
            this.trainee.random_per = '';
            this.showtraineeInfo = false;
            this.load = true;
            let entityIds = this.trainee.entity_id;
            let institute_info_id = '';
            if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
                institute_info_id = VueCookieNext.getCookie('_institute_info_id');
            }
            await ApiService.get(
                this.VUE_APP_INSTITUTE_LIST_API +
                '?entity_id=' +
                entityIds +
                '&institute_info_id=' +
                institute_info_id
            )
                .then((response) => {
                    this.institutes = response.data.data;
                    this.load = false;
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },
        async getClaim3Bill() {
            this.showtraineeInfo = false;
            this.load = true;
            let entity_id = this.trainee.entity_id;
            let institute_info_id = this.trainee.training_institute_id;
            if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
                entity_id = VueCookieNext.getCookie('_entity_id');
            }
            if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
                institute_info_id = VueCookieNext.getCookie('_institute_info_id');
            }
            await ApiService.get(
                'bill/claim_3/all_bill_no?entity_id=' +
                 entity_id +
                '&tranche_id=' +
                this.trainee.tranche_id +
                '&institute_info_id=' +
                institute_info_id
            )
                .then((response) => {
                this.allBillNo = response.data.data;
                this.load = false;
                })
                .catch(({ response }) => {
                console.log(response);
                });
        },
        
        async getTraineeList() {
            if (

                this.trainee.tranche_id != '' &&
                this.trainee.entity_id != '' &&
                this.trainee.bill_no != ''

            ) {
                this.load = true;
                await ApiService.get(
                    'trainee/joblist?entity_id=' +
                    this.trainee.entity_id +
                    '&tranche_id=' +
                    this.trainee.tranche_id +
                    '&training_institute_id=' +
                    this.trainee.training_institute_id +
                    '&bill_no=' +
                    this.trainee.bill_no +
                    '&random_per=' +
                    this.trainee.random_per
                )
                    .then((response) => {
                        this.load = false;
                        if(this.trainee.random_per){
                            this.total_trainee = response.data.data.total_trainee;
                            this.traineeList = response.data.data.trainee_list;
                        }else{
                            this.total_trainee = response.data.data.length;
                            this.traineeList = response.data.data;
                        }
                        this.showtraineeInfo = true;
                        this.componentKey += 1;
                    })
                    .catch(({ response }) => {
                        console.log(response);
                    });
            } else {
                Swal.fire({
                    title: 'Warning!',
                    html: 'Please Select All Required Field.',
                    icon: 'warning',
                    buttonsStyling: false,
                    confirmButtonText: 'Close',
                    customClass: {
                        confirmButton: 'btn btn-danger',
                    },
                });
            }
        },
    },
});
